import { defineStore } from "pinia";

export const useClientStore = defineStore("clientStore", {
  state: () => ({
    clients: [],
    loaded: false,
  }),
  actions: {
    // * Set the clients list and loaded state
    setClients(list, loaded) {
      this.clients = list;
      this.loaded = loaded;
    },

    // * Update the details of a client by id
    updateClientDetails(id, updatedDetails) {
      const client = this.clients.find((r) => r.id === id);
      if (client) {
        Object.assign(client, updatedDetails);
      } else {
        throw new Error("Client not found");
      }
    },

    // * Add a new client
    addClient(newClient) {
      if (newClient && newClient.id) {
        this.clients.push(newClient);
      } else {
        throw new Error("Invalid client object");
      }
    },

    // * Delete a client by id
    deleteClient(id) {
      this.clients = this.clients.filter((r) => r.id !== id);
    },
  },
});
