import { defineStore } from "pinia";

export const useRequestStore = defineStore("requestStore", {
  state: () => ({
    requests: [],
    loaded: false,
  }),
  actions: {
    // * Set the request list and loaded state
    setRequests(list, loaded) {
      this.requests = list;
      this.loaded = loaded;
    },

    // * Update the status of a request by id
    updateStatus(id, status) {
      const request = this.requests.find((r) => r.id === id);
      if (request) {
        request.status = status;
      }
    },

    // * Delete a request by id
    deleteRequest(id) {
      this.requests = this.requests.filter((r) => r.id !== id);
    },
  },
});
