import { defineStore } from "pinia";

// * Utility functions for arrayUnion and arrayRemove
const arrayUnion = (originalArray, newItem) => {
  if (!originalArray.includes(newItem)) {
    return [...originalArray, newItem]; // Add new item if not already present
  }
  return originalArray;
};

const arrayRemove = (originalArray, itemToRemove) => {
  return originalArray.filter((item) => item !== itemToRemove); // Remove specific item
};

export const useTripStore = defineStore("tripStore", {
  state: () => ({
    trips: [],
    loaded: false,
  }),
  actions: {
    // * Set the trip list and loaded state
    setTrips(list, loaded) {
      this.trips = list;
      this.loaded = loaded;
    },

    // * Update the details of a trip by id
    updateTripDetails(id, updatedDetails) {
      const trip = this.trips.find((r) => r.id === id);
      if (trip) {
        Object.assign(trip, updatedDetails);
      } else {
        throw new Error("Trip not found");
      }
    },

    // * Add an item to a trip's array field (arrayUnion)
    arrayUnionTripDetail(id, field, newItem) {
      const trip = this.trips.find((r) => r.id === id);
      if (trip && Array.isArray(trip[field])) {
        trip[field] = arrayUnion(trip[field], newItem);
      } else {
        throw new Error("Trip or field not found, or field is not an array");
      }
    },

    // * Remove an item from a trip's array field (arrayRemove)
    arrayRemoveTripDetail(id, field, itemToRemove) {
      const trip = this.trips.find((r) => r.id === id);
      if (trip && Array.isArray(trip[field])) {
        trip[field] = arrayRemove(trip[field], itemToRemove);
      } else {
        throw new Error("Trip or field not found, or field is not an array");
      }
    },

    // * Delete a trip by id
    deleteTrip(id) {
      this.trips = this.trips.filter((r) => r.id !== id);
    },
  },
});
