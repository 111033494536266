import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/styles/index.scss";
import { updateGlobalOptions, toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import Rellax from "rellax";
import { createPinia } from "pinia";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

const globalOptions = {
  mode: "auto",
};
const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(
  updateGlobalOptions({
    autoClose: 2500,
    position: toast.POSITION.TOP_CENTER,
    transition: toast.TRANSITIONS.SLIDE,
    hideProgressBar: true,
  })
);
app.use(VueTelInput, globalOptions);
app.component("EasyDataTable", Vue3EasyDataTable);

router.isReady().then(() => {
  app.mount("#app");
});

const globalMixin = {
  mounted() {
    // * Check if there are elements with the "rellax" class
    const rellaxElements = document.querySelectorAll(".rellax");
    if (rellaxElements.length > 0) {
      // * Initialize Rellax within the mounted hook
      new Rellax(".rellax");
    }
  },
};

app.mixin(globalMixin);
